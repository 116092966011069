import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MetaTags from './MetaTags';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); /* Darker background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is on top */

`;

const ModalContent = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  padding: 0;
  width: 90%;
  height: 90%;
  display: flex;
  padding: 20px;
  justify-content:center;
  align-items: center;
  overflow: hidden; /* Ensure no scrolling */

  @media (max-width: 768px) {
    flex-direction: column;
    justify-conent: center;
    width:100%;

  }

`;



const ModalImage = styled.img`
  max-width: 50%;
  max-height: 100%;
  object-fit: contain;
  // transition: transform 0.7s ease, width 0.7s ease, height 1s ease, top 1s ease, left 1s ease;
  position: relative;
  z-index: 1;

  &.expanded {
    transform: scale(1);
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3);
    position: absolute;
    z-index: 2;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100%;
    &.expanded {
    transform: scale(1);
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
  }


  }
`;

const ImageDescription = styled.div`
  color: white;
  width: 100%;
  font-family: "Cardo", serif;
  font-size: 18px;
  text-align: center;
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1;

  p{
    font-family: "Rubik", sans-serif;
    font-weight: 300;
  }
 
  &.expanded {
  // slowly fade out the description when the image is expanded
    opacity: 0;
    // transition: opacity 0.3s ease;

  }

  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
    height: 100%;
  }
`;


const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 3;
`;




function Modal({ image, onClose }) {
  const closeButtonRef = useRef(null);
  const imageRef = useRef(null);
  const descriptionRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const updateDescriptionHeight = () => {
    if (imageRef.current && descriptionRef.current) {
      descriptionRef.current.style.height = `${imageRef.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    updateDescriptionHeight();
    window.addEventListener('resize', updateDescriptionHeight);
    return () => {
      window.removeEventListener('resize', updateDescriptionHeight);
    };
  }, []);


  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);



  const handleImageClick = (e) => {
    // opens the image in a new tab
    // window.open(e.target.src, '_blank');
    setIsExpanded(!isExpanded)
  }
  



  return (
    <ModalWrapper onClick={onClose}
    >
    <MetaTags
      title={image?.header}
      description={image?.description}
      image={image?.url}
      url={window.location.href}

    />
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose} ref={closeButtonRef} aria-label="Close modal" >&times;</CloseButton>
        <ModalImage ref={imageRef} src={image.url} alt={`Full size image named: ${image?.header}`} onLoad={updateDescriptionHeight} onClick={handleImageClick} className={isExpanded ? 'expanded' : ''}/>
        <ImageDescription ref={descriptionRef} className={isExpanded? 'expanded': ''} >
          <h1>{image?.header}</h1>
          <p>{image?.description}</p>
        </ImageDescription>
      </ModalContent>
    </ModalWrapper>
  );
}

export default Modal;
