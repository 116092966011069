import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GalleryImage from '../components/GalleryImage';
import Modal from '../components/Modal';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
const LoadingSpinner = styled.div`
  border: 16px solid white;
  border-top: 16px solid var(--new-primary-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  // background: var(--primary-color);



`;

const Pgallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { imageName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      const URL = process.env.REACT_APP_API_URL;
      try {
        const { data } = await axios.get(`${URL}/pimages`);
        setImages(data);
        setLoading(false);
        if (imageName) {
          const selected = data.find(img => img.name === imageName);
          if (selected) {
            setSelectedImage(selected);
          }
        }
        
      } catch (error) {
        console.error('Error fetching images', error);
        setLoading(false);
      }

    }

    fetchImages();
  }, [])





  const openModal = (image) => {
    console.log('Opening modal for image:', image);
    setSelectedImage(image);
    navigate(`/pgallery/${encodeURIComponent(image.name)}`);
  };

  const closeModal = () => {
    setSelectedImage(null);
    navigate('/pgallery');
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <LoadingSpinner />
        </div>
      ) : (
        <GalleryWrapper>
          {images.map((image, index) => (
            <GalleryImage
              key={image.name}
              src={image.url}
              alt={`Art piece ${image.name}`}
              index={index}
              openModal={()=>{openModal(image)}}
              image={image}
              fromGallery={'pgallery'}
            />
          ))}
        </GalleryWrapper>
      )}
      {selectedImage && <Modal
        image={selectedImage}
        onClose={closeModal}
        aria-labelledby="View Art Piece"
        aria-describedby="View a larger version of the selected art piece"
      />}
    </>
  );
};

export default Pgallery;
