import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/clean-logo.svg';

const LogoLink = styled(Link)`
  grid-column: 6 / 8; /* Adjust the columns as needed */
  grid-row: 1 / 2;
  // border: 1px solid var(--new-primary-color);
  padding: 12.5px;
  min-height: 20px; /* Set a reasonable min-height */
  min-width: 50px; /* Set a reasonable min-width */
  justify-content: center;
  align-items: center;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* Ensure the logo maintains its aspect ratio */

  @media (max-width: 768px) {
    grid-column: 3/4
  }
`;
const Divider = styled.div`

  background: var(--new-primary-color);
  width: 100%;
  height: 12px;
`


const Nav = styled.nav`
  display: grid;
  // 12 columns grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  background: white;
  min-height: 80px;
  align-items: center;

    @media (max-width: 768px) {
    grid-template-columns: repeat(5,1fr);
  }
  
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: var(--new-primary-color);
  // border: 1px solid var(--new-primary-color);
  padding: 12.5px;
  &.home {
    grid-column: 2 / 3; /* spans columns 1 and 2 */
  }
  &.gallery {
    grid-column: 3 /4; /* spans columns 4 and 5 */
  }
  &.prints {
    grid-column: 4 / 5; /* spans columns 7 and 8 */
  }
  &.wildart {
    grid-column: 9 / 10; /* spans columns 7 and 8 */
  }

  &.about {
    grid-column: 10/ 11; /* spans columns 10 and 11 */
  }
  &.contact {
    grid-column: 11/ 13; /* spans column 12 */
  }
    @media (max-width: 768px) {
    display: ${props => (props.isOpen ? 'block' : 'none')};
    grid-column: 1 / -1;
    background: white;
    color: var(--new-primary-color);
    text-align: center;
    padding: 12.5px 0;
    border-bottom: 1px solid var(--new-primary-color);
  }
`;


const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: flex;
    grid-column: 1 / 2;
    justify-content: center;
    align-items: center;
  }
`;

const Bar = styled.div`
  width: 25px;
  height: 3px;
  background-color: var(--new-primary-color);
  margin: 4px 0;
`;
const MobileMenu = styled.div`
display: none;

@media (max-width: 768px) {
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  background: white;
  position: fixed;
  width: 25%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  padding-top: 40px;
  
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
}
`;

const MobileNavLink = styled(Link)`
  text-decoration: none;
  color: var(--new-primary-color);
  padding: 10px 0;
  font-size: 18px;
  border-bottom: 1px solid var(--new-primary-color);
`;


const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);



  return (
    <>
      <Nav role='navigation' aria-label="Main Navigation">
        <Hamburger onClick={toggleMenu}>
          <Bar />
          <Bar />
          <Bar />
        </Hamburger>
        <MobileMenu ref={menuRef} isOpen={isOpen} aria-controls="mobile-menu" aria-expanded={isOpen ? "true" : "false"} aria-label="Toggle navigation menu">
          <MobileNavLink to="/" onClick={() => { setIsOpen(false) }}>Home</MobileNavLink>
          <MobileNavLink to="/gallery" onClick={() => { setIsOpen(false) }}>Gallery</MobileNavLink>
          <MobileNavLink to="/pgallery" onClick={() => { setIsOpen(false) }}>Prints</MobileNavLink>
          <MobileNavLink to="/wildart" onClick={() => { setIsOpen(false) }}>Wild Art</MobileNavLink>
          <MobileNavLink to="/about" onClick={() => { setIsOpen(false) }}>About</MobileNavLink>
          <MobileNavLink to="/contact" onClick={() => { setIsOpen(false) }}>Contact Me</MobileNavLink>
        </MobileMenu>


        <NavLink to="/" className={'home'} aria-label="Home">Home</NavLink>
        <NavLink to="/gallery" className='gallery' aria-label='Gallery'>Gallery</NavLink>
        <NavLink to="/pgallery" className='prints' aria-label="Prints">Prints</NavLink>
        <NavLink to="/wildart" className='wildart' aria-label="Wild Art">Wild Art</NavLink>
        <NavLink to="/about" className='about' aria-label="About Dorit">About Dorit</NavLink>
        <NavLink to="/contact" className='contact' aria-label="Contact Me">Contact Me</NavLink>

        <LogoLink to="/" aria-label="Home">
        </LogoLink>
      </Nav>
      <Divider />
    </>
  );
};

export default NavBar;
