import React from 'react';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';





const HomeWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: top;
align-items: center;
height:100%;

`

const SmallGallery = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-gap: 5px;
    // align-items: center;
    // padding-top: 20px;

    & #pic1{
        width: 102%;
    }
    & #pic2{
        width: 100.05%; 
        z-index: 1;
        }
    & #pic3{
        width: 99.9%; 
        }
`

const ContentDiv = styled.div`
    ${props => props.flex ? 'display: flex;' : '0'}
    ${props => props.direction === 'column' ? 'flex-direction: column;' : '0'}
    ${props => props.direction === 'row' ? 'flex-direction: row;' : '0'}
    margin-right: ${props => props.align === 'left' ? 'auto' : '0'};;
    margin-left: ${props => props.align === 'right' ? 'auto' : '0'};;

    // stays centered on mobile
    @media (max-width: 768px) {

        flex-direction:column !important;
        justify-content: center;
        align-items: center;
    }

`

const Header = styled.h1`
// add a center option
    font-family: "Cardo", serif;
    font-weight: 400;
    color: var(--new-primary-color);

    font-size: 95.49px;


    props.align === 'center' ? 'text-align:center;' : '0'
    
    margin-right: ${props => props.align === 'right' ? 'auto' : '0'};
    margin-left: ${props => props.align === 'left' ? 'auto' : '0'};
    padding-left:${props => props.align === 'left' ? '10vw' : '0'} ;
    padding-right:${props => props.align === 'right' ? '10vw' : '0'} ;



    // stays centered on mobile
    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 80% !important;

        font-size: 45px;
    }
`;

const SubHeader = styled.h3`
    props.align === 'center' ? 'text-align:center;' : '0'
    margin-right: ${props => props.align === 'right' ? 'auto' : '0'};
    margin-left: ${props => props.align === 'left' ? 'auto' : '0'};
    padding-left:${props => props.align === 'left' ? '12vw' : '0'} ;
    padding-right:${props => props.align === 'right' ? '12vw' : '0'} ;
    
    font-family: "Rubik", sans-serif;
    font-size:45px;
    font-weight: 400;

    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;

        font-size: 25px;
    }
`;

const P = styled.p`

    props.align === 'center' ? 'text-align:center;' : '0'
    margin-right: ${props => props.align === 'right' ? 'auto' : '0'};
    margin-left: ${props => props.align === 'left' ? 'auto' : '0'};
    padding-left:${props => props.align === 'left' ? '12vw' : '0'} ;
    padding-right:${props => props.align === 'right' ? '12vw' : '0'} ;
    line-height: 1.3;

    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 300;
    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 20px !important;
        margin-right: 20px !important;

        font-size: 16px;
    }
`;

const Img = styled.img`


    width: 120%; /* Use viewport width for responsive sizing */
    max-width: 700px; /* Ensure it doesn't get too large */
    height: auto; /* Maintain aspect ratio */
    // object-fit: contain ; /* Ensure the image scales correctly */

    // stays centered on mobile
    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
`;

const Spacer = styled.div`
    height: ${props => props.height ? props.height : '20px'};
`;

const Divider = styled.div`

  background: var(--new-primary-color);
  width: 100%;
  height: 12px;
`
const Button = styled.button`
// contact me now button 
    background-color: var(--new-primary-color);
    color: white;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: 0.3s;
    &:hover {
        background-color: var(--new-primary-color-dark);
    }
    @media (max-width: 768px) {
        padding: 10px 15px;
        font-size: 18px;
    }
        
`;




function Home(props) {
    const nav = useNavigate();
    const URL = process.env.REACT_APP_API_URL;

    


    return (
        <>
            <HomeWrapper>
                <SmallGallery>
                    <Img src={`https://doritgur-server.onrender.com/images/fish-spiral.jpg`} id='pic1' alt="Dorit Gur Art - Abstract Painting 1" />
                    <Img src={`https://doritgur-server.onrender.com/images/hallelujah.jpg`} id='pic2' alt="Dorit Gur Art - Abstract Painting 2" />
                    <Img src={`https://doritgur-server.onrender.com/images/shir-lemalot.jpg`} id='pic3' alt="Dorit Gur Art - Abstract Painting 3" />

                </SmallGallery>




                <ContentDiv  >
                    <Header align='center' role='heading'>Welcome to the World of Dorit Gur</Header>
                    <SubHeader align='center' role='heading'>About Me and My Works</SubHeader>
                    <P align="center">
                        Dorit Gur is a renowned multi-disciplinary artist whose journey in art has spanned decades and continents.<br /><br />
                        Her works are distinguished by their vibrant colors and unique techniques,<br />
                        developed through years of experimentation.
                        <br /> <br />
                        Dorit's art is not just about visual appeal,it’s a blend of spiritual and cultural narratives,<br />
                        often featuring freehand biblical scripts.
                    </P>
                </ContentDiv>
                <Spacer height='100px' />
                <Divider  />
                <Spacer height='100px' />
                <ContentDiv direction='column' align='center'>
                    <SubHeader align='center' role="heading" aria-level="2">Custom Made Art</SubHeader>
                    <P align='center'>
                        Dorit welcomes you to be a part of her creative process.<br/> You can commission custom-made paintings or choose an existing piece<br/> and have it tailored with the colors and elements that resonate with you.<br/>

                        Contact Me Whether you are interested in purchasing a piece,<br/> commissioning a custom artwork, or simply want to know more about Dorit's artistic journey,<br/> feel free to reach out.
                    </P>
                    <br /><br />
                    <Button onClick={()=>{nav('/contact')}} aria-label='Contact Me'>Contact Me</Button>

                </ContentDiv>
                <Spacer height='200px' />
                



            </HomeWrapper>
        </>
    );
}

export default Home;