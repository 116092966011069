import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImageWrapper = styled(Link)`
  margin: 10px;
  border: 2px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: block;
  text-decoration: none;
`;

const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: 200px;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 95px;
    height: 95px;
  }
`;

const GalleryImage = ({ src, alt, index, image, openModal,fromGallery }) => {
  const handleClick = (e) => {
    e.preventDefault();
    openModal(image);
  };

  return (
    <ImageWrapper
      to={`/${fromGallery}/${encodeURIComponent(image.name)}`}
      onClick={handleClick}
      role="link"
      aria-label={`View art piece: ${image?.header}`}
    >
      <StyledLazyLoadImage
        src={src}
        alt={alt}
        effect="blur"
        threshold={100}
        wrapperProps={{
          style: {
            display: 'inline-block',
            width: '100%',
            height: '100%',
          },
        }}
      />
    </ImageWrapper>
  );
};

export default GalleryImage;