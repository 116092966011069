import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import workIcon from '../assets/clean-logo.svg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const DoritImages = styled.div`
    display:flex;
    width: 100%;
    justify-content: space-between;


    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

}

    

`
const Img = styled.img`
    width: 19.5vw;
    height: 80vh;
    // images are contained
    object-fit: cover;
    // images are centered

    @media (max-width: 768px) {
        width: 100%;
        height: 20vh;
    }


`
const P = styled.p`
    color: black;
    font-family: "Cardo", serif;
    text-align: left;
    padding: 14px 16px;
    text-decoration: none;
    // trim the text twords the center from left and from right
    text-align: center;
    text-justify: inter-word;
    

`

const H1 = styled.h1`
    font-family: "Rubik", serif;
    font-weight: 400;
    text-align: center;


`

const H3 = styled.h3`
    font-family: "Rubik", serif;
    font-weight: 600;


`

const H4 = styled.h4`
    font-family: "Cardo", serif;
    font-weight: 300;


`



function About2(props) {
    const URL = process.env.REACT_APP_API_URL;
    const nav = useNavigate();


    return (

        <>
            <DoritImages>
                <Img src={`${URL}/about/dorit4.jpg`} alt="Dorit Gur" />
                <Img src={`${URL}/about/dorit3.jpg`} alt="Dorit Gur" />
                <Img src={`${URL}/about/dorit1.jpg`} alt="Dorit Gur" />
                <Img src={`${URL}/about/dorit5.jpg`} alt="Dorit Gur" />
                <Img src={`${URL}/about/dorit2.jpg`} alt="Dorit Gur" />
            </DoritImages>
            <span style={{textAlign:'center'}}>
                <H1>About Dorit</H1>

                <P>
                    Dorit Gur is a multi-disciplinary artist, born in 1969, Israel
                    Her works are characterized by strong colors, combined with techniques mostly developed through trial and error, incorporating unconventional materials, As well as freehand writing of biblical scripts.
                    <br />
                    <br />
                    Dorit studied art focusing on painting, sculpturing and potery from various teachers. In addition she studied Kabbalah and Caligraphy..
                    Most of Dorit's works are created intuitively and without a preconceived plan, resulting in surprising outcomes.
                    Dorit presented her works in Israel, Los Angeles, New York, Jewish communities and synagogues.
                </P>
            </span>

            <H1>Exhibitions and Events</H1>





            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2008"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                // icon={<img src={workIcon} alt="Work Icon" style={{ width: '50%', height: '50%' }} />}
                >
                    <H3 className="vertical-timeline-element-title">"Bank HaPoalim" exhibition.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Tel Aviv, Israel</H4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2008"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Tel Aviv culture club "Creation".</H3>
                    <H4 className="vertical-timeline-element-subtitle"> Israel</H4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2009"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Rishon Le Zion, Israel</H4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2009"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Rishon Le Zion Art Hall.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Rishon Le Zion, Israel</H4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2012"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Dorit started collaborating with gallerys in Safed</H3>
                    <H4 className="vertical-timeline-element-subtitle"></H4>
                    <p>Various galleries in Safed have begun to exhibit and sell her works on a global scale.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2013(January)"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition - "I Am That I Am"(אהיה אשר אהיה) in Jewish Musium ,"Hechal Shlomo".</H3>
                    <H4 className="vertical-timeline-element-subtitle"> Jerusalem , Israel.</H4>
                    <img src={`${URL}/about/heichal-shlomo-exhibition.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2013(March)"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">"I Am That I Am" Continues 3 more months on public demand.</H3>
                    <H4 className="vertical-timeline-element-subtitle"> Jerusalem , Israel.</H4>
                    <img src={`${URL}/about/heichal-shlomo-exhibition-3months.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2013(August)"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Dorit opened a gallery where her works were permanently displayed</H3>
                    <H4 className="vertical-timeline-element-subtitle">Jaffa, Israel</H4>
                    <p> it also hosted exhibitions of various artists.
                    The gallery included a studio where she created her art behind transparent glass, allowing visitors to observe her at work. <br /></p>
                    <img src={`${URL}/about/dorit-gur-gallery.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/about/dorits-studio.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2013"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">"Jewish Modern Art" in the Jerusalem Cinematec.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Jerusalem, Israel</H4>
                    <img src={`${URL}/about/jerusalem-cinematec-exhibition.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2014"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition – "Ana Beckoah(אנא בכח)" in the W Tower.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Tel Aviv,Israel</H4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2014"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition– "Ana Beckoah(אנא בכח)" in the Rubinstein Tower.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Tel Aviv,Israel</H4>
                    <img src={`${URL}/about/rubinshtein-exhibition.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2014"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition– "Ana Beckoah(אנא בכח)" in the Silver Tower.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Ramat Gan,Israel</H4>
                    {/* <img src={`${URL}/about/rubinshtein-exhibition.jpg`} alt="Exhibition" style={{ width: '100%' }} /> */}
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2014"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Dorit created her first product "Keys Of Fortune".</H3>
                    <H4 className="vertical-timeline-element-subtitle"></H4>
                    <img src={`${URL}/about/keys-of-fortune.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/pimages/keys.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2015"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Dorit closed her gallery and went on exhibition tour "Blessing To The World".</H3>
                    <H4 className="vertical-timeline-element-subtitle">USA</H4>
                    <p>She also filmed a clip for the upcoming tour</p>
                    <img src={`${URL}/about/exhibition-tour.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                     
                    <iframe
                        width={"100%"}
                        height={"100%"}

                        src="https://www.youtube.com/embed/adgYljpk9dY"
                        title="Dorit Gur - Blessings to The World"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen></iframe>
                    <img src={`${URL}/about/painting-desert.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2015"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title"> Single artist exhibition – "Blessing to the world" in the Stamford JCC.</H3>
                    <H4 className="vertical-timeline-element-subtitle">CT, USA</H4>
                    <img src={`${URL}/about/exhibition-jcc.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/about/jewish-link-paper.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2015"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition – "Blessing to the world" in Mount Sinai Jewish Center.</H3>
                    <H4 className="vertical-timeline-element-subtitle">CT, USA</H4>
                    <img src={`${URL}/about/exhibition-jewish-center.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/about/news-article.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2015"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition – "Blessing to the world" in New Haven JCC, Woodbridge.</H3>
                    <H4 className="vertical-timeline-element-subtitle">CT, USA</H4>
                    <img src={`${URL}/about/exhibition-newhaven-jcc.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2015"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition in Slifka Center Yale University.</H3>
                    <H4 className="vertical-timeline-element-subtitle">CT, USA</H4>
                    <img src={`${URL}/about/yale-university-exhibition.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2016"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition in Temple Sholom.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Greenwich, CT, USA</H4>
                    <img src={`${URL}/about/usa-interview.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/about/usa-interview2.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2016"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition in Chase Family Gallery , Mandell JCC of Greater Hartford.</H3>
                    <H4 className="vertical-timeline-element-subtitle">West Hartford, CT ,USA</H4>
                    <img src={`${URL}/about/exhibition-chase.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2016"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Single artist exhibition in Temple Sholom.</H3>
                    <H4 className="vertical-timeline-element-subtitle">Greenwich, CT,USA</H4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2016"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">She collaborated in the design of the musical album. </H3>
                    <H4 className="vertical-timeline-element-subtitle"></H4>
                    <p>"Tefilat Ha'Adam" by the artists Yehoram Gaon and Ovadia Hamama, which featured her works.</p>
                    <img src={`${URL}/about/tfilat-ha-adam-disk.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/about/tfilat-ha-adam-disk2.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                    <img src={`${URL}/about/ahava-disk.jpg`} alt="Exhibition" style={{ width: '100%' }} />
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2017"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">She simultaneously began working with individuals with special needs. </H3>
                    <H4 className="vertical-timeline-element-subtitle"></H4>
                    <p>and produced the "Artizem" project (combining art and autism), where she co-created artworks with autistic individuals of varying functional levels.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2019"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Dorit won a precedent-setting legal case on copyright infringement. </H3>
                    <H4 className="vertical-timeline-element-subtitle"></H4>
                    <p>after her works were copied and forged by galleries she had worked with in Safed. <a href="https://www.ynet.co.il/articles/0,7340,L-5623266,00.html">(Article)</a></p>
                    {/* <img src={`${URL}/about/tfilat-ha-adam-disk.jpg`} alt="Exhibition" style={{ width: '100%' }} /> */}
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2020"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">Wild Art </H3>
                    <H4 className="vertical-timeline-element-subtitle"></H4>
                    <p>During the COVID-19 period, Dorit learned additional techniques that connected her to the wild nature.
                        These included botanical printing, weaving baskets from organic fabrics, creating wall jewelry from various tree seeds,
                        and embellishing a Moses staff made from olive wood.</p>
                    <img src={`${URL}/wildart/canvas_generic2.jpg`} alt="Exhibition" style={{ width: '100%' }} onClick={() => { nav('/wildart') }} />
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2023"
                    iconStyle={{ background: 'var(--new-primary-color)', color: '#fff' }}
                >
                    <H3 className="vertical-timeline-element-title">2023 - Seventh of October war</H3>
                    <H4 className="vertical-timeline-element-subtitle">she began creating Bohemian-style jewelry that included a charm with the Priestly Blessing and the Star of David.
                        She named this line "Nova" in memory of those who perished at the tragedy in Ra'im.</H4>
                    <p></p>
                    <img src={`${URL}/wildart/necklaces.jpeg`} alt="Exhibition" style={{ width: '100%' }} onClick={() => { nav('/wildart') }} />
                </VerticalTimelineElement>



            </VerticalTimeline>


        </>
    );
}

export default About2;
