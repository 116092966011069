import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import Pgallery from './pages/Pgallery';
import Contact from './pages/Contact';
import WildArt from './pages/WildArt';
import About2 from './pages/About2';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About2 />} />
        <Route path="/gallery" element={<Gallery />}>
          <Route path=":imageName" element={<Gallery />} />
        </Route>
        <Route path="/pgallery" element={<Pgallery/>} >
          <Route path=":imageName" element={<Pgallery />} />
        </Route>
        <Route path="/contact" element={<Contact/>} />
        <Route path="/wildart" element={<WildArt/>} >
          <Route path=":imageName" element={<WildArt />} />
        </Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;