import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    height: 10vh;
    width: 100%;
    background-color: var(--new-primary-color);
    color: white;
    font-size: 20px;
    font-family: 'Rubik', serif;
    font-weight: 300;

    @media (max-width: 768px) {
        flex-direction: column;
        height: 15vh;
    }
}
`;

const A = styled.a`
    text-decoration: none;
    color:black;

    &:hover {
        color: white;
        // transition
        transition: color 0.3s ease;

    }

    // make it look like a clickable link , by altering color
`;




function Footer(props) {
    return (
        <FooterWrapper aria-label='Sites footer'>
            {/* copyright dorit gur */}
            <span tabIndex='0'>

                © 2024 Dorit Gur. All rights reserved.
            </span>

            {/* website build by amit maymon with github link */}
            <span tabIndex='0' style={{fontSize:'16px'}}>
                Website built by <A href="https://github.com/AmitMaymon" role='button' aria-label="Link to website creator's github">Amit Maymon</A>
                
            </span>
        </FooterWrapper>
    );
}

export default Footer;