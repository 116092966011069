import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


const FormWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: flex;
    justify-content: top;
    height: 90vh;
    width: 100vw;
    order: 1;
 

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    input{
        width: 50%;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;


        
    }
    input:focus{

        border: 1px solid var(--new-primary-color);
    }


    textarea{
        width: 50%;
        height: 100px;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        resize: none;
    }
    // light blue button
    button{
        background-color: var(--new-primary-color);
        color: white;
        padding: 10px 20px;
        margin: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;

}


`

const ContentWrapper = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 25px;

`

const SubHeader = styled.h3`
    props.align === 'center' ? 'text-align:center;' : '0'
    margin-right: ${props => props.align === 'right' ? 'auto' : '0'};
    margin-left: ${props => props.align === 'left' ? 'auto' : '0'};
    padding-left:${props => props.align === 'left' ? '12vw' : '0'} ;
    padding-right:${props => props.align === 'right' ? '12vw' : '0'} ;
    
    font-family: "Rubik", sans-serif;
    font-size:45px;
    font-weight: 400;

    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;

        font-size: 25px;
    }
`;

const P = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0; /* Ensure no padding */
    margin: 0;  /* Ensure no margin */

    font-family: "Rubik", sans-serif;
    font-size: 1.1vw;
    font-weight: 300;
    line-height: 1.4;

    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 20px !important;
        margin-right: 20px !important;

        font-size: 14px;
        text-align: center;
    }
`;



const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    


`




function Contact(props) {
    const [contact, setContact] = useState({ fname: "", lname: "", email: "", message: "" });
    const nav = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        // check fields
        if (contact.fname === "" || contact.lname === "" || contact.email === "" || contact.message === "") {
            alert("All fields are required");
            return;
        }


        axios.post("https://doritgur-server.onrender.com/contact", contact)
            .then(res => {
                alert("Thank you for your message. We will get back to you shortly.");
                nav("/");
            })
            .catch(err => {
                console.log(err);
                alert("An error occurred. Please try again later.");
                nav("/");
            });

        setContact({ fname: "", lname: "", email: "", message: "" });

    }





    return (
        <>

            <FormWrapper>


                <Form onSubmit={handleSubmit}>

                    <ContentWrapper>

                        <SubHeader align="center">Get in Touch</SubHeader>
                        <P>Thank you for visiting the world of Dorit Gur.<br /> Whether you have questions about her artwork,<br /> are interested in a custom piece, or simply want to share your thoughts, we would love to hear from you.<br /> Your messages inspire and motivate Dorit to continue creating meaningful and beautiful art.<br />
                            <br />
                            Please fill out the form below with your details and message.<br /> Dorit personally reads every message and will get back to you as soon as possible.</P>

                    </ContentWrapper>
                    
                    <input
                        type="text"
                        id='fname'
                        name="fname"
                        aria-label='First Name'
                        placeholder="Your first name"
                        value={contact.fname}
                        onChange={(e) => setContact({ ...contact, fname: e.target.value })}
                        required
                    />
                    <input
                        type="text"
                        id='lname'
                        name="lname"
                        aria-label='Last Name'
                        placeholder="Your last name"
                        value={contact.lname}
                        onChange={(e) => setContact({ ...contact, lname: e.target.value })}
                        required
                    />
                    <input
                        type="email"
                        id='email'
                        name="email"
                        aria-label='Email'
                        placeholder="Your email"
                        value={contact.email}
                        onChange={(e) => setContact({ ...contact, email: e.target.value })}
                        required
                    />
                    <textarea
                        name="message"
                        id='message'
                        aria-label='Message'
                        placeholder="Your message"
                        value={contact.message}
                        onChange={(e) => setContact({ ...contact, message: e.target.value })}
                        required
                    ></textarea>
                    <button type="submit">Send</button>
                </Form>
            </FormWrapper>
        </>
    );
}

export default Contact;