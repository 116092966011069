import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import shadowLeaves from '../assets/leaves-shadow.svg';
import GalleryImage from '../components/GalleryImage';
import Modal from '../components/Modal';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';


const Wrapper = styled.div`
position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 60vh;
}

`;


const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${shadowLeaves});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;


//   contain on mobile from the top
    @media (max-width: 768px) {
        background-size: contain;
        background-position: top;
    }
`;

const Header = styled.h1`
  font-family: "Cardo", serif;
  font-weight: 400;
  color: var(--new-primary-color);
  font-size: 70px;
  text-align: center;
  z-index: 1;

  &#header-1 {
    margin-top: 11vh;

    @media (max-width: 768px) {
        // margin-top: -25vh;
        margin-bottom: 13vh;
}
  }

  @media (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 80% !important;
    font-size: 45px;

  }
`;


const P = styled.p`

    props.align === 'center' ? 'text-align:center;' : '0'
    margin-right: ${props => props.align === 'right' ? 'auto' : '0'};
    margin-left: ${props => props.align === 'left' ? 'auto' : '0'};
    padding-left:${props => props.align === 'left' ? '12vw' : '0'} ;
    padding-right:${props => props.align === 'right' ? '12vw' : '0'} ;
    line-height: 1.3;

    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 400;
    @media (max-width: 768px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: auto !important;
        margin-right: auto !important;

        font-size: 16px;

  
            

    }
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;


`;
const LoadingSpinner = styled.div`
  border: 16px solid white;
  border-top: 16px solid var(--new-primary-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


function WildArt(props) {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const { imageName } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchImages = async () => {
            const URL = process.env.REACT_APP_API_URL;

            try {
                const { data } = await axios.get(`${URL}/wildart`);

                setImages(data);
                setLoading(false);

                if (imageName) {
                    const selected = data.find(img => img.name === imageName);
                    if (selected) {
                        setSelectedImage(selected);
                    }
                }
            } catch (error) {
                console.error('Error fetching images', error);
                setLoading(false);
            }

        }

        fetchImages();
    }, [])



    const openModal = (image) => {
        console.log('Opening modal for image:', image);
        setSelectedImage(image);
        navigate(`/wildart/${encodeURIComponent(image.name)}`);
    };

    const closeModal = () => {
        setSelectedImage(null);
        navigate('/wildart');
    };



    return (
        <>
            <Wrapper id='test'>
                <BackgroundImage />

                <Header id='header-1'>Wild Art</Header>
                <P align="center" id='p-1'>Welcome to Wild Art, a unique and vibrant collection by Dorit Gur.<br /> This special line of art showcases her creative exploration of nature's raw beauty.<br /> Each piece is a burst of color and imagination,<br /> often crafted on natural items like rocks, wood, and organic materials.<br /> Dive into this extraordinary blend of nature and artistry, where every creation tells a story of its own.
                </P>

            </Wrapper>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <LoadingSpinner />
                </div>
            ) : (
                <GalleryWrapper>
                    {images.map((image, index) => (
                        <GalleryImage
                            key={image.name}
                            src={image.url}
                            alt={`Art piece ${image.name}`}
                            index={index}
                            openModal={() => { openModal(image) }}
                            image={image}
                        />
                    ))}
                </GalleryWrapper>
            )}

            {selectedImage && <Modal
                image={selectedImage}
                onClose={closeModal}
                aria-labelledby="View Art Piece"
                aria-describedby="View a larger version of the selected art piece"
            />}




        </>
    );
}

export default WildArt;